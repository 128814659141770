import React from "react";

const AddImage = () => (
  <svg
    width="35"
    height="31"
    viewBox="0 0 35 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="10.833"
      y="10.7236"
      width="22.4739"
      height="18.3875"
      stroke="currentColor"
      strokeWidth="2.35556"
    />
    <ellipse
      cx="15.9405"
      cy="15.8321"
      rx="1.02154"
      ry="1.02153"
      stroke="currentColor"
      stroke-width="2.35556"
    />
    <path
      d="M33.3069 22.9817L28.1992 16.8525L22.07 24.0032L19.0053 20.9386L10.833 29.1108"
      stroke="currentColor"
      strokeWidth="2.35556"
    />
    <path
      d="M7.38274 4.33543H11.1996V7.30168H7.38274V11.1186H4.41648V7.30168H0.599609V4.33543H4.41648V0.518555H7.38274V4.33543Z"
      fill="currentColor"
    />
  </svg>
);

export default AddImage;
