import React from "react";

const Attach = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M17.9706 3.12268C16.7781 2.00404 14.8787 1.67233 13.3326 3.12268L4.32295 11.5742C3.01927 12.7972 2.59241 14.0989 2.66598 15.2722C2.74138 16.4747 3.3509 17.6553 4.32295 18.5672C6.26625 20.3901 9.41705 20.9566 11.9642 18.5672L20.9733 10.1161L22.3421 11.5743L13.3326 20.0258C9.87333 23.2708 5.51613 22.4287 2.95463 20.0258C1.67428 18.8248 0.782202 17.1882 0.669903 15.3974C0.55578 13.5775 1.25512 11.7098 2.95463 10.1156L11.9642 1.66402C14.4224 -0.641886 17.5283 -0.034531 19.3389 1.66402C20.2439 2.51292 20.8857 3.67992 20.9667 4.97188C21.0496 6.29293 20.5379 7.63234 19.3389 8.75706L10.3294 17.2086C8.87226 18.5755 7.01773 18.2029 5.95783 17.2086C5.42828 16.7119 5.03673 16.0144 4.987 15.2213C4.93545 14.3991 5.25938 13.588 5.95783 12.9328L14.9671 4.48151C14.9672 4.48146 14.9674 4.4812 15.6516 5.21054C16.3357 5.93987 16.3357 5.9399 16.3357 5.93995L7.32615 14.3914C7.02354 14.6753 6.97207 14.9206 6.98308 15.0962C6.99592 15.3008 7.1049 15.5424 7.32615 15.75C7.76785 16.1643 8.41599 16.2612 8.96103 15.75L17.9706 7.29839C18.7738 6.54499 19.0129 5.7715 18.9706 5.09705C18.9265 4.3935 18.5673 3.68237 17.9706 3.12268Z"
          fill="#999999"/>
  </svg>

);

export default Attach;
