import React from "react";

const Bell = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M24.5857 16.6104C23.0793 15.5421 22.39 14.0357 22.5427 12.1354C22.6116 11.2837 22.6067 10.3778 22.5279 9.36368C22.3113 6.53291 20.854 4.56368 18.3137 3.66768C15.6651 2.73229 13.2282 3.26891 11.2639 5.21845C9.32912 7.13845 9.30943 9.53599 9.4522 11.8252C9.59005 13.9963 8.90574 15.6012 7.35989 16.7335C5.48913 18.1021 4.69159 19.968 4.85405 22.6117C4.92789 23.8326 5.29713 24.6449 6.8282 24.6449C6.85774 24.6449 6.8922 24.6449 6.92174 24.6449C8.24605 24.6154 9.5802 24.6104 10.9094 24.6104C11.4116 26.9292 13.3808 28.6523 15.739 28.7655C15.8177 28.7655 15.9014 28.7704 15.9802 28.7704C18.3974 28.7704 20.539 27.0129 21.0707 24.6203H25.2307C25.8805 24.6203 26.9734 24.6203 27.0965 23.1975C27.2885 20.9526 27.1359 18.4172 24.5808 16.6055L24.5857 16.6104ZM15.7931 27.7711C13.9667 27.6824 12.4257 26.3877 11.9482 24.6154C12.4159 24.6154 12.8885 24.6154 13.3513 24.6154C14.2325 24.6154 15.1137 24.6154 15.995 24.6203H20.0467C19.5199 26.5157 17.7476 27.8695 15.7931 27.7661V27.7711ZM26.107 23.1188C26.0676 23.5963 25.9691 23.6258 25.2208 23.6258H15.995C15.1137 23.6258 14.2325 23.6258 13.3513 23.6209C11.2393 23.616 9.05836 23.6061 6.90697 23.6504C6.06512 23.6751 5.91743 23.5175 5.85836 22.5526C5.71559 20.2535 6.36543 18.7077 7.95559 17.5458C9.77712 16.2166 10.619 14.272 10.4565 11.7661C10.3187 9.61968 10.3334 7.56183 11.9728 5.93229C13.1199 4.79014 14.4196 4.21906 15.8473 4.21906C16.5316 4.21906 17.2454 4.35199 17.9839 4.61291C20.15 5.38091 21.3464 7.00552 21.5334 9.44737C21.6073 10.4074 21.6122 11.264 21.5482 12.0615C21.366 14.3064 22.2177 16.1624 24.0097 17.4277C26.1464 18.944 26.2793 21.0609 26.107 23.1188Z"
      fill="currentColor"
    />
  </svg>
);

export default Bell;
