import React from "react";

const TfHours = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2845_19623)">
      <path
        d="M18.3026 19.905C18.1865 20.0843 18.0283 20.2741 17.849 20.4746C17.6696 20.675 17.4376 20.8965 17.1739 21.1497L14.4629 23.6919V25.0104H20.3595V23.5653H16.4882L18.0599 22.1518C18.4186 21.8353 18.7245 21.5505 18.9988 21.2763C19.273 21.002 19.4945 20.7277 19.6739 20.4535C19.8532 20.1792 19.9903 19.8944 20.0853 19.6096C20.1802 19.3248 20.2224 18.9978 20.2224 18.6391C20.2224 18.2805 20.1696 17.9535 20.0642 17.6476C19.9587 17.3417 19.7899 17.0674 19.5684 16.8353C19.3363 16.6033 19.0515 16.4134 18.6928 16.2763C18.3342 16.1391 17.9017 16.0758 17.3954 16.0758C16.7836 16.0758 16.2667 16.1497 15.8447 16.3079C15.4228 16.4556 15.0747 16.6244 14.8004 16.7931V18.3227C15.0958 18.1012 15.4334 17.9113 15.8237 17.7425C16.2139 17.5737 16.6359 17.4893 17.1 17.4893C17.6485 17.4893 18.0388 17.6265 18.2815 17.8902C18.5241 18.1644 18.6401 18.4914 18.6401 18.8817C18.6401 19.0716 18.6085 19.2404 18.5557 19.4092C18.503 19.5779 18.4186 19.7467 18.292 19.926L18.3026 19.905Z"
        fill="currentColor"
      />
      <path
        d="M26.7507 25.01V23.1324H28.1325V21.8244H26.7507V16.202H25.2211L21.2549 21.7927V23.1218H25.2844V24.9995H26.7507V25.01ZM22.8055 21.8349L24.6515 19.1661C24.757 19.0185 24.8625 18.8602 24.9785 18.6704C25.0945 18.4805 25.1789 18.3222 25.2528 18.2062H25.295V21.8349H22.8161H22.8055Z"
        fill="currentColor"
      />
      <path
        d="M35.6118 20.8333C35.6118 28.9768 28.9768 35.6118 20.8333 35.6118C12.6899 35.6118 6.05485 28.9873 6.05485 20.8333C6.05485 12.6793 12.6899 6.05485 20.8333 6.05485C23.6181 6.05485 26.3186 6.83544 28.6603 8.30169L26.4451 11.2553L30.1477 11.7089L33.8502 12.1624L32.384 8.72363L30.9177 5.28481L29.2827 7.45781C26.7511 5.85443 23.8291 5 20.8228 5C12.0992 5 5 12.0992 5 20.8333C5 29.5675 12.0992 36.6667 20.8333 36.6667C29.5675 36.6667 36.6667 29.5675 36.6667 20.8333H35.6118Z"
        fill="currentColor"
      />
      <path
        d="M10.0849 20.4215H8.30225V21.4764H10.0849V20.4215Z"
        fill="currentColor"
      />
      <path d="M21.488 9.14633H20.4331V10.929H21.488V9.14633Z" fill="currentColor" />
      <path
        d="M32.7641 20.2222H30.9814V21.277H32.7641V20.2222Z"
        fill="currentColor"
      />
      <path
        d="M21.6882 30.7804H20.6333V32.5631H21.6882V30.7804Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2845_19623">
        <rect
          width="31.6667"
          height="31.6667"
          fill="white"
          transform="translate(5 5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default TfHours;
