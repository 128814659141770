import React from "react";

const AddedCheckmark = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5298 6.02309C15.885 6.31567 15.9359 6.84085 15.6433 7.19612L9.80994 14.2795C9.66056 14.4608 9.44155 14.5707 9.20685 14.5821C8.97214 14.5934 8.74357 14.5051 8.57741 14.339L5.24408 11.0056C4.91864 10.6802 4.91864 10.1525 5.24408 9.82711C5.56951 9.50167 6.09715 9.50167 6.42259 9.82711L9.10689 12.5114L14.3567 6.13661C14.6493 5.78134 15.1745 5.73051 15.5298 6.02309Z"
      fill="white"
    />
  </svg>
);

export default AddedCheckmark;
