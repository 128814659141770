import React from "react";

const Bookmark = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 18.1475V3.81456C4 3.33972 4.15931 2.94333 4.47794 2.6254C4.79587 2.30677 5.19226 2.14746 5.6671 2.14746H14.72C15.1948 2.14746 15.5912 2.30677 15.9092 2.6254C16.2278 2.94333 16.3871 3.33972 16.3871 3.81456V18.1475L10.1935 15.4873L4 18.1475ZM5.03226 16.5475L10.1935 14.3281L15.3548 16.5475V3.81456C15.3548 3.65628 15.2888 3.51073 15.1566 3.37791C15.0238 3.24578 14.8783 3.17972 14.72 3.17972H5.6671C5.50882 3.17972 5.36327 3.24578 5.23045 3.37791C5.09832 3.51073 5.03226 3.65628 5.03226 3.81456V16.5475Z"
      fill="currentColor"
    />
  </svg>
);

export default Bookmark;
