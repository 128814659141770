import React from "react";

const AppoTracking = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M22.0657 13.1003H19.2957V12.1003H22.0657C23.0657 12.1003 23.8557 11.1603 23.8557 9.95031V6.22031C23.8557 5.07031 23.0157 4.07031 22.0657 4.07031H5.3457V3.07031H22.0657C23.5757 3.07031 24.8557 4.51031 24.8557 6.22031V9.95031C24.8557 11.7203 23.6257 13.1003 22.0657 13.1003Z"
        fill="currentColor"
      />
      <path
        d="M22.4063 23.55C21.0763 23.55 19.9863 22.46 19.9863 21.13C19.9863 19.8 21.0763 18.71 22.4063 18.71C23.7363 18.71 24.8263 19.8 24.8263 21.13C24.8263 22.46 23.7363 23.55 22.4063 23.55ZM22.4063 19.71C21.6263 19.71 20.9863 20.35 20.9863 21.13C20.9863 21.91 21.6263 22.55 22.4063 22.55C23.1863 22.55 23.8263 21.91 23.8263 21.13C23.8263 20.35 23.1863 19.71 22.4063 19.71Z"
        fill="currentColor"
      />
      <path
        d="M3.41609 5.99039C2.08609 5.99039 0.996094 4.90039 0.996094 3.57039C0.996094 2.24039 2.08609 1.15039 3.41609 1.15039C4.74609 1.15039 5.83609 2.24039 5.83609 3.57039C5.83609 4.90039 4.74609 5.99039 3.41609 5.99039ZM3.41609 2.15039C2.63609 2.15039 1.99609 2.79039 1.99609 3.57039C1.99609 4.35039 2.63609 4.99039 3.41609 4.99039C4.19609 4.99039 4.83609 4.35039 4.83609 3.57039C4.83609 2.79039 4.19609 2.15039 3.41609 2.15039Z"
        fill="currentColor"
      />
      <path
        d="M12.8848 15.0099C11.5548 15.0099 10.4648 13.9199 10.4648 12.5899C10.4648 11.2599 11.5548 10.1699 12.8848 10.1699C14.2148 10.1699 15.3048 11.2599 15.3048 12.5899C15.3048 13.9199 14.2148 15.0099 12.8848 15.0099ZM12.8848 11.1699C12.1048 11.1699 11.4648 11.8099 11.4648 12.5899C11.4648 13.3699 12.1048 14.0099 12.8848 14.0099C13.6648 14.0099 14.3048 13.3699 14.3048 12.5899C14.3048 11.8099 13.6648 11.1699 12.8848 11.1699Z"
        fill="currentColor"
      />
      <path
        d="M22.3952 12.0889H14.6152V13.0889H22.3952V12.0889Z"
        fill="currentColor"
      />
      <path
        d="M20.1949 21.6301H4.08492C2.54492 21.6301 1.29492 20.2701 1.29492 18.6001V15.1101C1.29492 13.4401 2.54492 12.0801 4.08492 12.0801H10.9649V13.0801H4.08492C2.97492 13.0801 2.29492 14.1301 2.29492 15.1101V18.6001C2.29492 19.7201 3.09492 20.6301 4.08492 20.6301H20.1949V21.6301Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default AppoTracking;
