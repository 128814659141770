import React from "react";

const ChangeDate = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.447 91.961" width={48} height={45}>
    <g
      id="change_date"
      data-name="change date"
      transform="translate(-12 3.988)"
    >
      <g id="Group_3923" data-name="Group 3923" transform="translate(14 7.012)">
        <line
          id="Line_245"
          data-name="Line 245"
          x2="23.688"
          transform="translate(27.636 5.922)"
          fill="none"
          stroke="#d1d3d4"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          id="Path_408"
          data-name="Path 408"
          d="M-600.884-89h7.9a3.947,3.947,0,0,1,3.948,3.948V-19.91a3.947,3.947,0,0,1-3.948,3.948h-71.064A3.947,3.947,0,0,1-668-19.91V-85.052A3.947,3.947,0,0,1-664.052-89h7.9"
          transform="translate(668 94.922)"
          fill="none"
          stroke="#d1d3d4"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <g id="Group_2413" data-name="Group 2413" transform="translate(19.74)">
          <line
            id="Line_246"
            data-name="Line 246"
            y2="11.844"
            fill="none"
            stroke="#d1d3d4"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeWidth="4"
          />
          <line
            id="Line_247"
            data-name="Line 247"
            y2="11.844"
            transform="translate(39.48)"
            fill="none"
            stroke="#d1d3d4"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </g>
        <rect
          id="Rectangle_628"
          data-name="Rectangle 628"
          width="11.844"
          height="11.844"
          transform="translate(13.818 33.558)"
          fill="none"
          stroke="#d1d3d4"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <rect
          id="Rectangle_629"
          data-name="Rectangle 629"
          width="11.844"
          height="11.844"
          transform="translate(13.818 55.272)"
          fill="none"
          stroke="#d1d3d4"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <rect
          id="Rectangle_630"
          data-name="Rectangle 630"
          width="11.844"
          height="11.844"
          transform="translate(33.558 33.558)"
          fill="none"
          stroke="#d1d3d4"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <rect
          id="Rectangle_631"
          data-name="Rectangle 631"
          width="11.844"
          height="11.844"
          transform="translate(33.558 55.272)"
          fill="none"
          stroke="#d1d3d4"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <rect
          id="Rectangle_632"
          data-name="Rectangle 632"
          width="11.844"
          height="11.844"
          transform="translate(53.298 33.558)"
          fill="none"
          stroke="#d1d3d4"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <line
          id="Line_355"
          data-name="Line 355"
          x2="63.168"
          transform="translate(7.896 21.714)"
          fill="none"
          stroke="#d1d3d4"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
        />
      </g>
      <g id="Group_3720" data-name="Group 3720" transform="translate(76 0.012)">
        <circle
          id="Ellipse_1619"
          data-name="Ellipse 1619"
          cx="18.724"
          cy="18.724"
          r="18.724"
          transform="translate(-4 -4)"
          fill="#fff"
        />
        <circle
          id="Ellipse_20"
          data-name="Ellipse 20"
          cx="14.724"
          cy="14.724"
          r="14.724"
          fill="#fff"
          stroke="#0c479d"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <g
          id="Group_10642"
          data-name="Group 10642"
          transform="translate(0 -0.537)"
        >
          <path
            id="Path_6474"
            data-name="Path 6474"
            d="M-365.588,15.942v5.647a.706.706,0,0,1-.706.706h-12a.706.706,0,0,1-.706-.706V11.706a.706.706,0,0,1,.706-.706h6.353"
            transform="translate(386.78 -0.685)"
            fill="none"
            stroke="#0c479d"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <g
            id="Group_10640"
            data-name="Group 10640"
            transform="translate(13.073 8.197)"
          >
            <g id="Group_2436" data-name="Group 2436" transform="translate(0)">
              <path
                id="Path_527"
                data-name="Path 527"
                d="M-361.968,14.177H-364V12.145l6.935-6.935a.718.718,0,0,1,1.016,0l1.016,1.016a.718.718,0,0,1,0,1.016Z"
                transform="translate(364 -5)"
                fill="none"
                stroke="#0c479d"
                strokeLinecap="square"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </g>
          </g>
          <line
            id="Line_8003"
            data-name="Line 8003"
            x1="1.819"
            y1="1.819"
            transform="translate(18.471 10.213)"
            fill="none"
            stroke="#0c479d"
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ChangeDate;
