import React from "react";

const ArrowRight = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2097 0.387101L7.29289 0.292893C7.65338 -0.0675907 8.22061 -0.0953203 8.6129 0.209705L8.70711 0.292893L11.7071 3.29289L11.7485 3.33685L11.8037 3.40469L11.8753 3.51594L11.9288 3.62866L11.9642 3.73401L11.9931 3.8819L12 4L11.9972 4.07524L11.9798 4.20073L11.9503 4.31214L11.9063 4.42322L11.854 4.52071L11.7803 4.62545L11.7071 4.70711L8.70711 7.70711C8.31658 8.09763 7.68342 8.09763 7.29289 7.70711C6.93241 7.34662 6.90468 6.77939 7.2097 6.3871L7.29289 6.29289L8.585 5H1C0.447715 5 0 4.55228 0 4C0 3.44772 0.447715 3 1 3H8.585L7.29289 1.70711C6.93241 1.34662 6.90468 0.779392 7.2097 0.387101L7.29289 0.292893L7.2097 0.387101Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowRight;
