import React from "react";

const Benefits = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3961_128504)">
      <path
        d="M12.2009 19.4329H1.51758V0.599609H15.2676V10.1329H14.4342V1.43294H2.35091V18.5996H12.2009V19.4329Z"
        fill="currentColor"
      />
      <path
        d="M17.0176 12.767L14.1842 15.6003L12.8509 14.267C12.6842 14.1003 12.5176 14.1003 12.3509 14.267L11.3509 15.267C11.2676 15.3503 11.2676 15.4336 11.2676 15.517C11.2676 15.6003 11.3509 15.6836 11.3509 15.767L13.8509 18.1003C13.9342 18.1836 14.0176 18.1836 14.1009 18.1836C14.1842 18.1836 14.2676 18.1836 14.3509 18.1003L18.4342 14.017C18.5176 13.9336 18.5176 13.8503 18.5176 13.767C18.5176 13.6836 18.4342 13.6003 18.4342 13.517L17.5176 12.6836C17.3509 12.6003 17.1842 12.6003 17.0176 12.767ZM17.6842 13.8503L14.1842 17.4336L12.1842 15.517L12.6842 15.017L14.0176 16.3503C14.1842 16.517 14.3509 16.517 14.5176 16.3503L17.4342 13.4336L17.6842 13.8503Z"
        fill="currentColor"
      />
      <path
        d="M14.8503 20.2669C12.0003 20.2669 9.68359 17.9503 9.68359 15.1003C9.68359 12.2503 12.0003 9.93359 14.8503 9.93359C17.7003 9.93359 20.0169 12.2503 20.0169 15.1003C20.0169 17.9503 17.7003 20.2669 14.8503 20.2669ZM14.8503 10.7669C12.4586 10.7669 10.5169 12.7086 10.5169 15.1003C10.5169 17.4919 12.4586 19.4336 14.8503 19.4336C17.2419 19.4336 19.1836 17.4919 19.1836 15.1003C19.1836 12.7086 17.2419 10.7669 14.8503 10.7669Z"
        fill="currentColor"
      />
      <path
        d="M8.80794 9.70801H7.97461V11.8997H8.80794V9.70801Z"
        fill="currentColor"
      />
      <path
        d="M8.39154 10.5417C7.1582 10.5417 6.1582 9.69167 6.1582 8.65833V8.24167H6.99154V8.65833C6.99154 9.24167 7.61654 9.70833 8.39154 9.70833C9.16654 9.70833 9.79154 9.23333 9.79154 8.65833C9.79154 8.175 9.3332 7.625 8.71654 7.625H8.0832C7.06654 7.625 6.17487 6.75 6.17487 5.75833C6.17487 4.76667 7.17487 3.875 8.4082 3.875C9.64154 3.875 10.6415 4.725 10.6415 5.75833V6.175H9.8082V5.75833C9.8082 5.175 9.1832 4.70833 8.4082 4.70833C7.6332 4.70833 7.0082 5.18333 7.0082 5.75833C7.0082 6.24167 7.46654 6.79167 8.0832 6.79167H8.71654C9.7332 6.79167 10.6249 7.66667 10.6249 8.65833C10.6249 9.65 9.62487 10.5417 8.39154 10.5417Z"
        fill="currentColor"
      />
      <path
        d="M8.80794 2.5166H7.97461V4.70827H8.80794V2.5166Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3961_128504">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Benefits;
