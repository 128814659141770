import React from "react";

const ArrowBarRight = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.63672 10L21.0004 10" stroke="currentColor" strokeWidth="2" transform="rotate(180, 10.5, 10)" />
    <path d="M11.9094 18L4.63672 10L11.9094 2" stroke="currentColor" strokeWidth="2" transform="rotate(180, 10.5, 10)" />
    <path d="M1.90909 20L1.90909 1.08408e-08" stroke="currentColor" strokeWidth="2" transform="rotate(180, 10.5, 10)" />
  </svg>
);

export default ArrowBarRight;
